import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import IntlContext from './../IntlContext'
import { Link } from 'react-router-dom'
import { IMAGE_CDN_URL } from '../../config'
import { styledWithRTL } from '../../utils/styles'

const initContext = {
  items: [],
  set: (itemList) => {},
  pop: () => {}
}
const BreadcrumbContext = createContext(initContext)
export const BreadcrumbProvider = ({ children, initItems = [] }) => {
  const [items, setItems] = useState(initItems)
  initContext.set = (itemList) => {
    if (itemList instanceof Array) {
      setItems([...itemList])
    }
  }
  initContext.pop = () => {
    setItems((items) => {
      items.pop()
      return items
    })
  }

  return (
    <BreadcrumbContext.Provider
      value={{
        items,
        set: initContext.set,
        pop: initContext.pop
      }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
export const useBreadcrumbs = () => useContext(BreadcrumbContext)

const BreadcrumbList = styledWithRTL('ul')`
	list-style: none;

	li{
		position: relative;
		display: inline-block;
		padding-right: 24px;
    color: ${({ theme: { colors } }) => `${colors.text.primary}`};
		font-weight: ${({
      theme: {
        typography: {
          text: {
            bold: { fontWeight }
          }
        }
      }
    }) => fontWeight};

		&::after{
			position: absolute;
			content:'';
			background-image: url(${IMAGE_CDN_URL('chevron-up.svg')});
			background-repeat: no-repeat;
			background-size: contain;
			width: 8px;
			height: 8px;
			transform: rotate(-90deg);
			right: 8px;
			top: 5px;
		}

		a{
			color: ${({
        theme: {
          colors: { link }
        }
      }) => link};
		}

		&:last-child{
			padding-right: 0;

			&::after{
				display: none;
			}

			a{
				color: inherit;
				cursor: default;
			}
		}
	}
`
const Breadcrumbs = ({ items }) => {
  return (
    <IntlContext.Consumer>
      {({ toIntlString }) => (
        <BreadcrumbProvider>
          <BreadcrumbList data-testid="breadcrumbs-list">
            {items.map(({ displayKey, route }, index) => {
              const displayText = toIntlString(displayKey)
              return (
                <li key={`${displayKey}-${index}`} data-testid="breadcrumb-list-item">
                  {route ? <Link to={route}>{displayText}</Link> : displayText}
                </li>
              )
            })}
          </BreadcrumbList>
        </BreadcrumbProvider>
      )}
    </IntlContext.Consumer>
  )
}
Breadcrumbs.defaultProps = {
  items: []
}
Breadcrumbs.propTypes = {
  items: PropTypes.array
}

const BreadcrumbsFromContext = () => {
  const { items } = useBreadcrumbs()
  return <Breadcrumbs items={items} />
}
BreadcrumbsFromContext.defaultProps = {
  items: []
}
BreadcrumbsFromContext.propTypes = {
  items: PropTypes.array
}

export { Breadcrumbs, BreadcrumbsFromContext }
