// This file is to write utilities for supporting RTL languages
import styled, { css } from 'react-emotion'
import cssjanus from 'cssjanus'
import reactToCSS from 'react-style-object-to-css'
import { templeFunctionArgsParser } from '../utility'
import { getUserLanguageFromLocalStorageOrFallbackToDefault, isRTLLanguage } from '../langUtils'

function composeTagParsedParams(partsArr) {
  const params = []
  const templateParts = []
  let i = 0
  while (i < partsArr.length) {
    templateParts.push(partsArr[i++])
    if (!partsArr[i]) {
      break
    }
    params.push(partsArr[i++])
  }
  templateParts.raw = [...templateParts]
  return [templateParts, ...params]
}

const _isCurrentLanguageRTL = () =>
  isRTLLanguage(getUserLanguageFromLocalStorageOrFallbackToDefault())

const _transformStyles = (style) => (_isCurrentLanguageRTL() ? cssjanus.transform(style) : style)

/* Wrapper for styled components which transforms css properties */
function styledWithRTL(elem, options = {}) {
  return function (...args) {
    const styledRTL = templeFunctionArgsParser(args, _transformStyles)
    return styled(elem, options)(...composeTagParsedParams(styledRTL))
  }
}

/* Utility function tor return RTL transformed css
  Works with both taaggedTemplate and React style object */
function cssWithRTL(...args) {
  if (args.length && args[0] instanceof Array) {
    return css(templeFunctionArgsParser(args, _transformStyles))
  }
  return css`
    ${_transformStyles(reactToCSS(args[0]))}
  `
}

export { styledWithRTL, cssWithRTL }
