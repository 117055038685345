import { ENV } from '../../utils/utility'

export const LoginRequestHeadersConfig = {
  // Currently these routes are involved in login flow
  // TODO : For now we are maintaining this soon this will be moved to
  // Independent Login Page app as mentioned in this task FRESHID-88188
  forRoutes: ['login', 'login-consent', 'magiclink', 'forgot-password', 'mfa'],
  headers: {
    'x-request-target': 'login'
  }
}

// For now I have defaulted this function with "LoginRequestHeadersConfig" as the login headers are the only route based header
// need for now later if more activities need different headers to be sent we can revisit the default parameter later by
// creating default function for each type of headers. These can also be extended to do something else also. This can be scaled
// till we can modify the entire config by passing a custom function into the axios interceptor which can take and return the config
export const getPathNameBasedHeadersValue = (
  currentFullPathName = window.location.pathname || '',
  requestHeadersConfig = LoginRequestHeadersConfig
) => {
  const currentRouteName = currentFullPathName.split('/')
  if (
    requestHeadersConfig.forRoutes.some((routeName) => currentRouteName.indexOf(routeName) !== -1)
  ) {
    return requestHeadersConfig.headers
  }
  return {}
}

const activities = {
  test: {
    method: 'GET',
    url: '/test'
  },
  test1: {
    method: 'GET',
    url: '/test1'
  },
  getCurrentUserDetails: {
    method: 'GET',
    url: '/users/current'
  },
  updateCurrentUserDetails: {
    method: 'PATCH',
    url: '/users/current'
  },
  changePassword: {
    method: 'PUT',
    url: '/user/password'
  },
  getOrgPasswordEnabled: {
    method: 'GET',
    url: '/authentications/-/password/enabled'
  },
  getAllowedLoginMethods: {
    method: 'GET',
    url: (shouldQueryMfa = false, hash = false) => {
      let queryParams = {
        fetch_mfa_configuration: shouldQueryMfa,
        hash_code: hash
      }
      let queryString = ''

      for (const [key, value] of Object.entries(queryParams)) {
        if (value) {
          queryString += `&${key}=${value}`
        }
      }
      if (queryString) {
        queryString = `?${queryString.slice(1)}`
      }
      return `/authentications${queryString}`
    },
    getAdditionalHeadersWithPathName: getPathNameBasedHeadersValue
  },
  setAllowedLoginMethods: {
    method: 'PATCH',
    url: '/authentications'
  },
  createAuthenticationModule: {
    method: 'POST',
    url: '/authentications'
  },
  deleteAuthenticationModule: {
    method: 'DELETE',
    url: (id) => `/authentications/${id}`
  },
  checkIfOrgDomainExists: {
    method: 'GET',
    url: ((protocol, baseUrl, namespace, orgDomain) =>
      `${protocol}//${baseUrl}/${namespace}/organisations/domain/${orgDomain}/available`).bind(
      this,
      window.location.protocol,
      process.env.REACT_APP_FID_GLOBAL_HOST,
      ENV.NAMESPACE
    )
  },
  getErrorDescription: {
    method: 'GET',
    url: (errorDescriptionId) => `/errors/${errorDescriptionId}`
  },
  loginViaEmail: {
    method: 'POST',
    url: '/login'
  },
  resolveLoginHint: {
    method: 'POST',
    url: '/login_hint/resolve'
  },
  requestResetPasswordLink: {
    method: 'POST',
    url: '/users/reset-password'
  },
  requestMagicLink: {
    method: 'POST',
    url: '/magiclink/email/send'
  },
  loginWithMagicLinkHash: {
    method: 'POST',
    url: (hash) => `/magiclink/${hash}/login`
  },
  requestActivationEmail: {
    method: 'PATCH',
    url: (email) => `/users/${email}/resend`
  },
  getOrgDetails: {
    method: 'GET',
    url: '/organisations'
  },
  setOrgDetails: {
    method: 'PATCH',
    url: '/organisations'
  },
  getOrgSummary: {
    method: 'GET',
    url: '/organisations/-/summary'
  },
  getOrgConfig: {
    method: 'GET',
    url: '/organisations/-/appInitPayload',
    getAdditionalHeadersWithPathName: getPathNameBasedHeadersValue
  },
  patchUpdateOrganisationCustomisations: {
    method: 'PATCH',
    url: '/organisation-customisations'
  },
  checkActivationHash: {
    method: 'GET',
    url: (activationHash) => `/users/-/hashes/${activationHash}/activation`
  },
  getUserDetailsFromHash: {
    method: 'GET',
    url: (activationHash) => `/users/-/hashes/${activationHash}/details`
  },
  activateUser: {
    method: 'PATCH',
    url: (activationHash) => `/users/-/hashes/${activationHash}/activate`
  },
  signIn: {
    method: 'POST',
    /*This activity has no predefined url, FE gets the url as a response from another API response,
    hence returning the same url which is passed in pathFragment of DataManager. */
    url: (url) => url
  },
  getAllTransferAccounts: {
    method: 'GET',
    url: '/accounts/transfers'
  },
  transferAccount: {
    method: 'POST',
    url: '/accounts/transfers'
  },
  updateAccountTransfer: {
    method: 'PATCH',
    url: '/accounts/transfers'
  },
  getTransferDetails: {
    method: 'GET',
    url: (transferToken) => `/accounts/transfers/${transferToken}`
  },
  session: {
    method: 'POST',
    url: '/session',
    isLoggingDisabled: true //To prevent this api call from logging in heap
  },
  unlockUser: {
    method: 'PATCH',
    url: (unlockHash) => `/users/-/hashes/${unlockHash}/unlock`
  },
  checkPasswordHash: {
    method: 'GET',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/set-password`
  },
  setUserPassword: {
    method: 'PATCH',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/set-password`
  },
  getAllUsers: {
    method: 'GET',
    url: '/users/search/filter'
  },
  getUserDetails: {
    method: 'GET',
    url: (uuid) => `/users/${uuid}`
  },
  setUserDetails: {
    method: 'PATCH',
    url: (uuid) => `/users/${uuid}`
  },
  updateUserAdminStatus: {
    method: 'PATCH',
    url: (uuid) => `/admins/${uuid}`
  },
  deleteUser: {
    method: 'DELETE',
    url: (uuid) => `/users/${uuid}`
  },
  getAllProducts: {
    method: 'GET',
    url: '/products',
    getAdditionalHeadersWithPathName: getPathNameBasedHeadersValue
  },
  getAccounts: {
    method: 'GET',
    url: '/accounts'
  },
  getResetHashDetails: {
    method: 'GET',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/reset-password`
  },
  resetUserPassword: {
    method: 'PATCH',
    url: (passwordHash) => `/users/-/hashes/${passwordHash}/reset-password`
  },
  updateUserDetails: {
    method: 'PATCH',
    url: (uuid) => `/users/${uuid}`
  },
  getOrgImages: {
    method: 'GET',
    url: '/organisation/-/images'
  },
  deleteOrgImages: {
    method: 'DELETE',
    url: (imageContext) => `/organisation/-/images/${imageContext}`
  },
  getUserMfaStatus: {
    method: 'GET',
    url: (userId) => `/users/${userId}/mfa/status`
  },
  enrollMfa: {
    method: 'POST',
    url: '/organisations/-/users/-/mfa/enroll'
  },
  verifyMfaCode: {
    method: 'POST',
    url: (mfaId) => `/mfa/${mfaId}/lifecycle/activate`
  },
  loginMfaActivate: {
    method: 'POST',
    url: (mfaId) => `/login/mfa/${mfaId}/activate`
  },
  deactivateMfa: {
    method: 'POST',
    url: (userId) => `/user/${userId}/mfa/deactivate`
  },
  loginMfa: {
    method: 'POST',
    url: '/login/mfa'
  },
  resendOTP: {
    method: 'POST',
    url: '/login/mfa/resend'
  },
  setMfaActive: {
    method: 'POST',
    url: (userId) => `/user/${userId}/mfa/setactive`
  },
  requestMfaRecoveryCode: {
    method: 'POST',
    url: '/organisations/-/users/-/mfa/recovery/request'
  },
  sendRecoveryCode: {
    method: 'POST',
    url: (userId) => `/organisations/-/users/${userId}/mfa/recovery/send`
  },
  getAllEntryPoints: {
    method: 'GET',
    //Conditionally support user_type
    url: (userType) => `/entrypoints${!!userType ? `?user_type=${userType}` : ''}`
  },
  getCountOfAccountsAndPortals: {
    method: 'GET',
    url: (userType) => `/entrypoints/-/count${!!userType ? `?user_type=${userType}` : ''}`
  },
  createEntryPoints: {
    method: 'POST',
    url: '/entrypoints'
  },
  getEntryPointById: {
    method: 'GET',
    url: (entryPointId, shouldQueryMfa = false) =>
      `/entrypoints/${entryPointId}${shouldQueryMfa ? '?fetch_mfa_configuration=true' : ''}`
  },
  updateEntryPoint: {
    method: 'PATCH',
    url: (entryPointId) => `/entrypoints/${entryPointId}`
  },
  deleteEntryPoint: {
    method: 'DELETE',
    url: (entryPointId) => `/entrypoints/${entryPointId}`
  },
  deleteOrganization: {
    method: 'DELETE',
    url: `/organisations`
  },
  getCustomDomainDetails: {
    method: 'GET',
    url: '/organisation/domains',
    useActivityMocks: true
  },
  createCustomDomainRequest: {
    method: 'POST',
    url: '/organisation/domains',
    useActivityMocks: true
  },
  createCustomDomainRedesignRequest: {
    method: 'POST',
    url: '/organisation/domains/cname'
  },
  editCustomDomainRequest: {
    method: 'PATCH',
    url: '/organisation/domains',
    useActivityMocks: true
  },
  verifyCustomDomainRequest: {
    method: 'POST',
    url: '/organisation/domains/verify',
    useActivityMocks: true
  },
  verifyCustomDomainRedesignRequest: {
    method: 'POST',
    url: '/organisation/custom-domain'
  },
  requestCustomDomainCertificate: {
    method: 'POST',
    url: '/organisation/domains/certificate',
    useActivityMocks: true
  },
  checkCustomDomainCertificateStatus: {
    method: 'POST',
    url: '/organisation/domains/certificate/status',
    useActivityMocks: true
  },
  activateCustomDomain: {
    method: 'PATCH',
    url: '/organisation/domains/activate',
    useActivityMocks: true
  },
  cancelCustomDomainRequest: {
    method: 'DELETE',
    url: '/organisation/domains',
    useActivityMocks: true
  },
  deleteEntryPointImage: {
    method: 'DELETE',
    url: (entryPointId) => `/entrypoints/${entryPointId}/image`
  },
  // Give the list of accounts & portals mapped to entry points
  entryPointAccAndPortalMapping: {
    method: 'GET',
    url: '/entrypoints/-/mapping'
  },
  getAllOrgPortals: {
    method: 'GET',
    url: '/accounts/-/portals'
  },
  getPortalsByAccount: {
    method: 'GET',
    url: (account_id) => `/accounts/${account_id}/portals`
  },
  updateUserPassword: {
    method: 'PUT',
    url: 'user/reset-password'
  },
  //Billing related api definitions start
  getInvoices: {
    method: 'POST',
    url: 'ubx/invoices/list'
  },
  dueInvoiceDetails: {
    method: 'POST',
    url: 'ubx/invoices/due_invoice_details'
  },
  downloadInvoice: {
    method: 'GET',
    url: (invoiceId) => `ubx/invoices/${invoiceId}/pdf`
  },
  getSubscriptions: {
    method: 'GET',
    url: `ubx/organisations/-/subscriptions`
  },
  cancelSubscription: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/cancel`
  },
  getAddresses: {
    method: 'GET',
    url: `ubx/organisations/-/addresses`
  },
  updateCustomerInfo: {
    method: 'POST',
    url: (customerId) => `ubx/billing-customers/${customerId}/billing-info`
  },
  chargeAddon: {
    method: 'POST',
    url: `ubx/invoices/charge_addon`
  },
  autoRecharge: {
    method: 'POST',
    url: `ubx/invoices/auto_recharge`
  },
  getBrandInfoFromPlanId: {
    method: 'GET',
    url: (planId) => `ubx/brands/-/plans/${planId}`
  },
  getBundleFeatureMatrix: {
    method: 'GET',
    url: (subscriptionId) => `ubx/plans/-/features/${subscriptionId}`
  },
  getPlans: {
    method: 'GET',
    url: `ubx/plans`
  },
  getAddonsByIds: {
    method: 'POST',
    url: `ubx/addons/getAddonsById`
  },
  getSubscriptionRenewalEstimate: {
    method: 'GET',
    url: (subscriptionID) => `ubx/subscriptions/${subscriptionID}/renewal_estimate`
  },
  viewScheduleChanges: {
    method: 'GET',
    url: (subscriptionID) => `ubx/subscriptions/${subscriptionID}/change_request`
  },
  updateSubThroughHostedPage: {
    method: 'POST',
    url: `ubx/hosted_pages/checkout_existing`
  },
  updateSubsThroughAPI: {
    method: 'POST',
    url: (subscriptionID) => `ubx/subscriptions/${subscriptionID}`
  },
  updateSubInterim: {
    method: 'POST',
    url: (subscriptionID) => `ubx/subscriptions/${subscriptionID}/update_interim`
  },
  getUpdatePaymentHostedPage: {
    method: 'POST',
    url: 'ubx/hosted_pages/update_payment_method'
  },
  updatePaymentMethodUsingToken: {
    method: 'POST',
    url: 'ubx/payment_sources/create_using_token'
  },
  getSubscriptionEstimate: {
    method: 'POST',
    url: 'ubx/estimates/update_subscription'
  },
  getEstimateForCreateSubscription: {
    method: 'POST',
    url: 'ubx/estimates/create_subscription'
  },
  cancelScheduledSubscriptionChanges: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/remove_scheduled_changes`
  },
  reactivateSubscription: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/reactivate`
  },
  createSubscription: {
    method: 'POST',
    url: 'ubx/subscriptions'
  },
  switchCurrency: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/switch_currency`
  },
  deleteSubscription: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/delete`
  },
  validateDowngrade: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/validate_downgrade`
  },
  getAddonUsageDetails: {
    method: 'GET',
    url: (addonId, subscriptionId) => `/ubx/subscriptions/${subscriptionId}/usage/addons/${addonId}`
  },
  autoActivateSubscription: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/trial-end-state`
  },
  captureIntent: {
    method: 'POST',
    url: (subscriptionId) => `/ubx/subscriptions/${subscriptionId}/capture-intent`
  },
  //Billing related api definitions end
  getAuditLogs: {
    method: 'POST',
    url: '/activity'
  },
  getUBXAuditLogs: {
    method: 'GET',
    url: (searchParam) => `/ubx/audit?${searchParam || ''}`
  },
  getUBXActionCategories: {
    method: 'GET',
    url: '/ubx/metadata'
  },
  getEventCategories: {
    method: 'GET',
    url: '/events/categories'
  },
  removeMembersFromGroup: {
    method: 'DELETE',
    url: (userGroupId) => `/usergroups/${userGroupId}/members`
  },
  updateUserGroupMembers: {
    method: 'PATCH',
    url: (groupId) => `/usergroups/${groupId}/members/type`
  },
  getAllOrgUserGroups: {
    method: 'GET',
    url: '/usergroups'
  },
  getGroupDetails: {
    method: 'GET',
    url: (groupId) => `/usergroups/${groupId}`
  },
  addUserToGroups: {
    method: 'POST',
    url: '/users/-/usergroups'
  },
  getAllOrgBundles: {
    method: 'GET',
    url: '/bundles'
  },
  getAllBundlesTypes: {
    method: 'GET',
    url: '/bundletypes'
  },
  getAllCloudTypes: {
    method: 'GET',
    url: '/cloudtypes',
    getAdditionalHeadersWithPathName: getPathNameBasedHeadersValue
  },
  addUser: {
    method: 'POST',
    url: '/users/accounts'
  },
  updateUserAccounts: {
    method: 'PATCH',
    url: (uuid) => `/users/${uuid}/accounts`
  },
  deleteUserAccount: {
    method: 'DELETE',
    url: (domain, uuid) => `/accounts/domain/${domain}/users/${uuid}`
  },
  createUserGroup: {
    method: 'POST',
    url: '/usergroups/-/members'
  },
  getGroupMembers: {
    method: 'GET',
    url: (groupId) => `/usergroups/${groupId}/members`
  },
  editGroup: {
    method: 'PATCH',
    url: '/usergroups/-/members'
  },
  deleteGroup: {
    method: 'DELETE',
    url: (groupId) => `/usergroups/${groupId}`
  },
  updateUserEmail: {
    method: 'PATCH',
    url: (userId) => `/users/${userId}/email`
  },
  globalSearch: {
    method: 'POST',
    url: '/search'
  },
  adminResetUserPassword: {
    method: 'PUT',
    url: (userId) => `/user/${userId}/password`
  },
  getOrganisationCustomisations: {
    method: 'GET',
    url: '/organisation-customisations'
  },
  updateOrganisationCustomisations: {
    method: 'PUT',
    url: '/organisation-customisations'
  },
  getAllowedUserTypes: {
    method: 'GET',
    url: (userId) => `/users/${userId}/user-types`
  },
  getAuthorizedScopes: {
    method: 'GET',
    url: 'oauth2/authorize/scopes'
  },
  getAppDetails: {
    method: 'GET',
    url: (clientId) => `oauth2/apps/client_id/${clientId}`
  },
  validateDayPassConsent: {
    method: 'POST',
    url: '/day_pass/validate'
  },
  activateDayPass: {
    method: 'POST',
    url: '/day_pass/activate'
  },
  switchPlanValidation: {
    method: 'POST',
    url: (subscriptionId) => `ubx/subscriptions/${subscriptionId}/modify`
  },
  getAllAdminRoles: {
    method: 'GET',
    url: '/rbac/roles'
  },
  createRole: {
    method: 'POST',
    url: '/rbac/roles'
  },
  getRoleById: {
    method: 'GET',
    url: (roleId) => `/rbac/roles/${roleId}`
  },
  editRoleById: {
    method: 'PATCH',
    url: (roleId) => `/rbac/roles/${roleId}`
  },
  deleteRoleById: {
    method: 'DELETE',
    url: (roleId) => `/rbac/roles/${roleId}`
  },
  getUserRoleMapping: {
    method: 'GET',
    url: (roleId) => `/rbac/users`
  },

  getUserRoleMappingForOrgUser: {
    method: 'GET',
    url: () => `/rbac/users`
  },
  searchForUser: {
    method: 'GET',
    url: (roleId) => `/rbac/roles/${roleId}/list_users/assignable_status`
  },
  addUserRoleMapping: {
    method: 'POST',
    url: (roleId) => `/rbac/roles/${roleId}/users`
  },
  deleteUserRoleMapping: {
    method: 'DELETE',
    url: (roleId) => `/rbac/roles/${roleId}/users`
  },
  updateUserRoleMapping: {
    method: 'PUT',
    url: (userId) => `/rbac/users/${userId}/roles`
  },
  getAllUserRoles: {
    method: 'GET',
    url: (userId) => `/rbac/users/${userId}/roles`
  },
  getActiveUserSessions: {
    method: 'GET',
    url: 'user/sessions'
  },
  deleteActiveUserSession: {
    method: 'POST',
    url: 'user/session-management/delete'
  },
  getFeatureEntity: {
    method: 'GET',
    url: `/ubx/plans/-/entity-feature/difference`
  }
}
export default activities
