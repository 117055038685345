import ENV from '../env.config.json'

/**
 * @Desc - handles the case when URL loaded is -
 *    {productDomain}{prefix}/some-other-path
 *                  or
 *    {productDomain}{prefex}
 *                  or
 *    {orgDomain}/organisation
 */
export const isRenderedUnderPrefix =
  window.location.pathname.indexOf(`${ENV.PREFIX}/`) === 0 ||
  window.location.pathname === `${ENV.PREFIX}`
const DEFAULT_REDIRECT_PAGE = '/'
const LOGIN_PATHNAME = '/login'
const DEFAULT_REDIRECT_PATH = `${window.location.origin}${isRenderedUnderPrefix ? ENV.PREFIX : ''}`

const SUPPORT_EMAIL = 'support@freshworks.com'
const RECEIVABLES_EMAIL = 'receivables@freshworks.com'
const DEFAULT_DISPLAY_RANK = -1
const OMNICHANNNEL_DISPLAY_RANK = 80
const BUNDLE_TYPE_NAMES = {
  SALES360: 'SALES360',
  SUPPORT360: 'SUPPORT360',
  FS_FR: 'FRESHSERVICE'
}
const TOTP = 'TOTP'
// in seconds
const _MINUTE_IN_SECONDS = 1 * 60
const _HOUR_IN_SECONDS = 1 * 60 * _MINUTE_IN_SECONDS
const _DAY_IN_SECONDS = 1 * 24 * _HOUR_IN_SECONDS
const THIRTY_DAYS_IN_SECONDS = 30 * _DAY_IN_SECONDS
const ENTITY_TYPES = {
  AUDIT_LOGS: 'audit_logs',
  ORG_LEVEL_SECURITY: 'org_security',
  USER_LEVEL_SECURITY: 'user_security',
  USER_MANAGEMENT: 'user_management'
}

export const NUMBER_OF_CUSTOM_ROLES_USER_CAN_ADD = 10

const ACTIONS = {
  READ: 'read',
  UPDATE: 'write'
}
const APP_CONSTANTS = {
  SCREEN_SIZES: {
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1500
  },
  RE_AUTH_PATHNAME: '/login/reauth',
  SECURITY_AGENTS_PATHNAME: '/security/agents',
  LOGIN_PATHNAME,
  PRODUCT_NAMES: {
    FRESHDESK: 'freshdesk',
    FRESHSERVICE: 'freshservice',
    FRESHCHAT: 'freshchat',
    FRESHCALLER: 'freshcaller',
    OMNICHANNEL: 'omnichannel'
  },
  BUNDLE_TYPE_NAMES,
  FRESHWORKS: 'Freshworks',
  DEFAULT_LANGUAGE: 'en-US',
  DURATION: {
    //in ms
    SECOND: 1 * 1000,
    MINUTE: _MINUTE_IN_SECONDS * 1000,
    HOUR: _HOUR_IN_SECONDS * 1000
  },
  // local storage key for storing language option
  USER_LANGUAGE_PATH: 'freshworks-common-login:language-code',
  // TRANSLATION_LOCATION is created on the fly on build time
  TRANSLATION_LOCATION: 'assets/translations',
  RTL_LANGUAGES_LCID: ['he-IL', 'ar-SA'],
  REDIRECT_URI: 'redirect_uri',
  PRODUCT_REDIRECT_URI: 'product_redirect_uri',
  CLIENT_ID: 'client_id',
  POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
  SESSION_STATE: 'session_state',
  ERROR: 'error',
  ERRORS_TO_REPORT: ['SERVER_ERROR'],
  EMAIL: 'email',
  FORM_FIELD_CONSTANTS: {
    OLD_PASSWORDS_MAX_LENGTH: 80,
    PASSWORD_LENGTH: {
      MIN: 8,
      MAX: 80
    },
    LENGTH_VALIDATION_KEY: 'size'
  },
  // This is the max length value set by backend
  // for email ids during user creation
  EMAIL_MAX_LENGTH: 128,
  AUTHENTICATION_METHODS: {
    PASSWORD: 'PASSWORD',
    MAGICLINK: 'MAGICLINK',
    GOOGLE: 'GOOGLE',
    SSO: 'SSO',
    SHOPIFY: 'SHOPIFY'
  },
  PASSWORD_POLICY: {
    PASSWORD_EXPIRY_DAYS: 'passwordExpiryDays',
    NO_PERSONAL_INFO: 'noPersonalInfo',
    HISTORY_COUNT: 'historyCount',
    SAME_AS_OLD_PASSWORD: 'sameAsOldPassword',
    VALIDATED_IN: {
      SERVER: 'SERVER',
      CLIENT: 'CLIENT'
    },
    PASSWORD_POLICY_LEVELS: {
      LOW: 'LOW',
      MEDIUM: 'MEDIUM',
      HIGH: 'HIGH',
      CUSTOM: 'CUSTOM'
    },
    PASSWORD_ENFORCEMENT_POLICY: {
      IMMEDIATE: 'IMMEDIATE',
      NEXT_LOGIN: 'NEXT_LOGIN',
      PASSWORD_CHANGE: 'PASSWORD_CHANGE'
    }
  },
  // @TODO remove these and rely on SecuritySetting/utils once Old Security is retired
  SSO_LOGIN_METHODS: {
    OIDC: 'OIDC',
    OAUTH: 'OAUTH',
    SAML: 'SAML',
    JWT_SSO: 'JWT_SSO'
  },
  SSO_SUPPORTED_INPUT_TYPES: {
    TEXT: 'text',
    TEXT_AREA: 'textarea',
    DROPDOWN: 'dropdown',
    PASSWORD: 'password',
    SWITCH: 'switch',
    RADIO: 'radio',
    CUSTOM: 'custom' //created for future use where a field can be object and directly modelled into another component
  },
  // this has circular dep between security/utils and SecurityHelpSection, hence having it here
  AVAILABLE_HELP_TOPICS: {
    SECURITY_HELP: 'SECURITY_HELP',
    SESSION_MANAGEMENT_HELP: 'SESSION_MANAGEMENT_HELP',
    EDIT_FIELD_PERMISSIONS_HELP: 'EDIT_FIELD_PERMISSIONS_HELP'
  },
  AVAILABLE_IDLE_SESSION_TIMEOUT_IN_SECONDS: [
    0, // 0 mins, if idle session timeout is not set at all 🙇🏻‍♂️
    5 * _MINUTE_IN_SECONDS, // 5 mins
    10 * _MINUTE_IN_SECONDS, // 10 mins
    15 * _MINUTE_IN_SECONDS, // 15 mins
    30 * _MINUTE_IN_SECONDS, // 30 mins
    45 * _MINUTE_IN_SECONDS, // 45 mins
    60 * _MINUTE_IN_SECONDS // 60 mins
  ],
  AVAILABLE_SESSION_TIMEOUT_IN_SECONDS: [
    8 * _HOUR_IN_SECONDS, // 8 hours
    12 * _HOUR_IN_SECONDS, // 12 hours
    24 * _HOUR_IN_SECONDS, // 24 hour or 1 day
    2 * _DAY_IN_SECONDS, // 2 days
    5 * _DAY_IN_SECONDS, // 5 days
    7 * _DAY_IN_SECONDS, // 7 days
    15 * _DAY_IN_SECONDS, // 15 days
    30 * _DAY_IN_SECONDS // 30 days (By default BE will send 30 days)
  ],
  UNSPECIFIED_IDP_TYPE: 'UNSPECIFIED_IDP',
  SUPPORT_EMAIL,
  RECEIVABLES_EMAIL,
  DEFAULT_REDIRECT_PAGE,
  THIRTY_DAYS_IN_SECONDS,
  HOUR_IN_SECONDS: _HOUR_IN_SECONDS,
  DAY_IN_SECONDS: _DAY_IN_SECONDS,
  DEFAULT_REDIRECT_PATH: DEFAULT_REDIRECT_PATH,
  DEFAULT_REDIRECT_URL: `${DEFAULT_REDIRECT_PATH}${DEFAULT_REDIRECT_PAGE}`,
  LOGIN_REDIRECT_URL: `${DEFAULT_REDIRECT_PATH}${LOGIN_PATHNAME}`,
  FRESHWORKS_HOME: 'https://www.freshworks.com',
  FRESHWORKS_TERMS_CONDITIONS: 'https://www.freshworks.com/terms/',
  FRESHWORKS_PRIVACY: 'https://www.freshworks.com/privacy/',
  SUPPORT_LINKS: {
    SECURITY_ARTICLE: 'https://support.freshworks.com/support/solutions/folders/274996',
    ORG_WHATS_NEW:
      'https://support.freshworks.com/support/solutions/articles/240019-what-s-new-with-freshworks-organisation',
    IMPORT_ACCOUNT:
      'https://support.freshworks.com/support/solutions/articles/238840-importing-account-to-an-organization'
  },
  WEB_STORAGE: {
    LOCAL_STORAGE: 'localStorage'
  },
  MFA: {
    DEFAULT_MFA_TYPE: TOTP,
    TYPES: {
      TOTP,
      EMAIL: 'EMAIL'
    },
    STATE: {
      DISABLED: 'DISABLED',
      ACTIVATED: 'ACTIVATED'
    },
    FROM_LOGIN: 'fromLogin',
    FROM_INVITE: 'fromInvite',
    MFA_REQUIRED: 'MFA_REQUIRED',
    MFA_CONFIGURATION_REQUIRED: 'MFA_CONFIGURATION_REQUIRED',
    MFA_CONFIGURATION_OPTIONAL: 'MFA_CONFIGURATION_OPTIONAL',
    SUPPORT_LINK:
      'https://support.freshworks.com/support/solutions/articles/50000001025-using-two-factor-authentication'
  },
  UPDATE_PASSWORD: {
    PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
  },
  // add user roles to the `ROLES` object below, and make sure to maintain the same
  // `value` of the roles key below for `keys` when used in role name translations
  // eg: In constants -> ORG_ADMIN: `org_admin` || In translations -> "org_admin": "Org - Admin"
  //the roles come as a part of userTypes api from BE
  ROLES: {
    ORG_ADMIN: 'org_admin',
    USER: 'USER',
    RESTRICTED_REQUESTOR: 'restricted_requestor',
    OTHER_ROLES: 'other_roles',
    RESTRICTED_REQUESTOR_DISP_NAME: 'RESTRICTED REQUESTOR',
    RESTRICTED_REQUESTER: 'RESTRICTED REQUESTER',
    ORGANIZATION_ADMIN: 'ORGANIZATION ADMIN',
    ORGANIZATION_USER: 'ORGANIZATION USER',
    ORGUSER: 'user',
    ORG_USER: 'organization_user',
    ORGADMIN: 'organization_admin'
  },
  RESTRICTED_USER_PRODUCTS: ['freshservice'],
  ERROR_STRINGS: {
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    UNEXPECTED_RESPONSE: 'UNEXPECTED_RESPONSE',
    ORGANISATION_DOMAIN_UPDATED: 'ORGANISATION_DOMAIN_UPDATED',
    ZIP_CODE_ERROR: 'INVALID_ZIP_CODE',
    PRODUCT_INTENT_RAISED: 'PRODUCT_INTENT_RAISED',
    CAPTURE_INTENT: 'CAPTURE_INTENT'
  },
  AUDIT_LOGS: {
    GREEN: 'green',
    AMBER: 'amber',
    RED: 'red',
    NORMAL: 'normal'
  },
  UBX_AUDIT_LOGS: {
    GREEN: 'high',
    RED: 'critical',
    BLUE: 'moderate',
    AMBER: 'low'
  },
  RESPONSE_STATUSES: {
    ERRORS: {
      BAD_REQUEST: 400,
      NOT_FOUND: 404
    }
  },
  CHARGEBEE_JS_V2: 'https://js.chargebee.com/v2/chargebee.js',
  CHARGEBEE_JS_V1: 'https://js.chargebee.com/v1/chargebee.js',
  FRESHCHAT_WIDGET: {
    STAGING: {
      SCRIPT: 'https://wchat-staging.freshpori.com/js/widget.js',
      URL: 'https://wchat-staging.freshpori.com',
      TOKEN: 'c30fd42e-849a-4844-9a3a-246f66f34d0e'
    },
    PRODUCTION: {
      SCRIPT: 'https://wchat.freshchat.com/js/widget.js',
      URL: 'https://wchat.freshchat.com',
      TOKEN: '0cf5dc01-f58a-47e0-9a2e-8cce19e5f1a3'
    },
    TAGS: ['support'],
    CHATBOT_STAGING_EMAILS: [
      'freshidautomation@freshworks.com',
      'freshid@freshbugs.com',
      'bfreshworkstest@gmail.com'
    ]
  },
  GROUP_ROLES: {
    USER: 'USER',
    MANAGER: 'MANAGER'
  },
  FALLBACK_USERNAME: '- -',
  MOVE_EXISTING_ACCOUNT_OPEN: {
    QUERY_PARAM_KEY: 'focus',
    QUERY_PARAM_VALUE: 'move_existing_account'
  },
  ACCOUNT_STATUS: {
    ACTIVATED: 'ACTIVATED',
    INACTIVE: 'INACTIVE'
  },
  AGENT_TYPE: {
    FULL_TIME_AGENT: 'Full time agent',
    OCCASIONAL_AGENT: 'Occasional agent'
  },
  ACCOUNT_DELETE_STATUS: {
    ACCOUNT_CREATED: 'ACCOUNT_CREATED',
    MARKED_FOR_DELETION: 'MARKED_FOR_DELETION',
    DELETE_FAILURE: 'DELETE_FAILURE'
  },
  EDIT_PROFILE: {
    QUERY_PARAM_KEY: 'edit_profile',
    QUERY_PARAM_VALUE: 'true',
    UPDATE_SUCCESS_PARAM_KEY: 'update_success'
  },
  CONSENT_PARAM_KEY: 'consent',
  SLUG_PARAM_KEY: 'slug',
  ORIGIN: 'origin',
  LOGIN_HINT_PARAM_KEY: 'login_hint',
  GLOBAL_USER_SEARCH: 'GLOBAL_USER_SEARCH',
  STEP_UP_AUTH_TYPE_PARAM_KEY: 'step_up_auth_type',
  USER_STATUS: {
    ACTIVATED: 'ACTIVATED'
  },
  CLOUD_TYPE_SUPPORTED_BUNDLES: new Set([
    BUNDLE_TYPE_NAMES.SUPPORT360,
    BUNDLE_TYPE_NAMES.SALES360,
    BUNDLE_TYPE_NAMES.FS_FR
  ]),
  BUNDLE_OFFERING_TYPE: {
    BUNDLE: 'BUNDLE_TYPE',
    CLOUD_TYPE: 'CLOUD_TYPE'
  },
  DEFAULT_DISPLAY_RANK,
  OFFERING_DISPLAY_RANK: {
    // Sorted based on display_rank in descending order
    FRESHDESK: 60,
    FRESHSALES: DEFAULT_DISPLAY_RANK,
    FRESHTEAM: 10,
    FRESHCALLER: 40,
    FRESHSERVICE: 20,
    FRESHSERVICE_BUSINESS_TEAMS: 19,
    FRESHMARKETER: DEFAULT_DISPLAY_RANK,
    FRESHCONNECT: DEFAULT_DISPLAY_RANK,
    FRESHRELEASE: DEFAULT_DISPLAY_RANK,
    FRESHSUCESS: DEFAULT_DISPLAY_RANK,
    SALES_CLOUD: 50,
    MARKETING_CLOUD: 45,
    TELEPHONY: 40,
    FRESHDESK_MESSAGING_CLOUD: 42,
    CUSTOMER_FOR_LIFE_CLOUD: 55,
    FRESHDESK_FRESHSUCCESS_CLOUD: 39,
    [BUNDLE_TYPE_NAMES.SUPPORT360]: OMNICHANNNEL_DISPLAY_RANK, // for backward compatibility
    OMNICHANNEL: OMNICHANNNEL_DISPLAY_RANK,
    MESSAGING: 70,
    CONVERSATIONS: 65,
    FRESHCHAT: 30,
    FRESHSURVEY: 5,
    // UX want to display the Markeplace product after
    // all other FW products hence adding display rank
    // of -2 to it.
    MARKETPLACE: -2
    // @todo: check if FreshPing and FreshStatus needs to be added
  },
  USER_PROFILE_RESTRICTION_LEVELS: {
    RESTRICTION_ALLOW_ALL: 'RESTRICTION_ALLOW_ALL',
    RESTRICTION_ALLOW_NONE: 'RESTRICTION_ALLOW_NONE',
    RESTRICTION_ALLOW_SOME: 'RESTRICTION_ALLOW_SOME'
  },
  RECAPTCHA_LOAD_WAIT_TIME: 10000,
  CUSTOMIZED_IMAGES_TYPES: {
    ORG_LOGO: 'ORG_LOGO',
    ORG_FAVICON: 'ORG_FAVICON'
  },
  YES_OR_NO_FLAG: {
    YES: 'yes',
    NO: 'no'
  },
  NAV_ITEMS: {
    DASHBOARD: 'dashboard',
    SECURITY: 'security',
    USERS_AND_GROUPS: 'users-and-groups',
    SUBSCRIPTION: 'subscription',
    ORGANIZATION: 'organization',
    AUDIT_LOGS: 'audit-logs'
  },
  NAV_ITEMS_PRIVILEGES: {
    'users-and-groups': [
      `${ENTITY_TYPES.USER_MANAGEMENT}_${ACTIONS.READ}`,
      `${ENTITY_TYPES.USER_LEVEL_SECURITY}_${ACTIONS.READ}`
    ],
    security: [`${ENTITY_TYPES.ORG_LEVEL_SECURITY}_${ACTIONS.READ}`],
    'audit-logs': [`${ENTITY_TYPES.AUDIT_LOGS}_${ACTIONS.READ}`]
  },
  SUBSCRIPTION_ICON_LOCAL_STORAGE_KEY: 'show-subscription-icon',
  ORG_PATHS: {
    SET_ORG_SESSION: 'setSessionCookie'
  },
  PRIVILEGES_KEYS: {
    USER_MANAGEMENT: {
      READ: [
        `${ENTITY_TYPES.USER_MANAGEMENT}_${ACTIONS.READ}`,
        `${ENTITY_TYPES.USER_LEVEL_SECURITY}_${ACTIONS.READ}`
      ],
      UPDATE: [`${ENTITY_TYPES.USER_MANAGEMENT}_${ACTIONS.UPDATE}`],
      READ_WITHOUT_SECURITY: [`${ENTITY_TYPES.USER_MANAGEMENT}_${ACTIONS.READ}`]
    },
    USER_LEVEL_SECURITY_MANAGEMENT: {
      READ: [`${ENTITY_TYPES.USER_LEVEL_SECURITY}_${ACTIONS.READ}`],
      UPDATE: [`${ENTITY_TYPES.USER_LEVEL_SECURITY}_${ACTIONS.UPDATE}`]
    },
    AUDIT_LOGS: {
      READ: [`${ENTITY_TYPES.AUDIT_LOGS}_${ACTIONS.READ}`],
      UPDATE: [`${ENTITY_TYPES.AUDIT_LOGS}_${ACTIONS.READ}`]
    },
    SECURITY: {
      READ: [`${ENTITY_TYPES.ORG_LEVEL_SECURITY}_${ACTIONS.READ}`],
      UPDATE: [`${ENTITY_TYPES.ORG_LEVEL_SECURITY}_${ACTIONS.UPDATE}`]
    },
    VIEW_USER_ROLE: {
      READ: [`${ENTITY_TYPES.USER_MANAGEMENT}_${ACTIONS.READ}`],
      UPDATE: [`${ENTITY_TYPES.USER_MANAGEMENT}_${ACTIONS.READ}`]
    }
  },
  API_POST_AUTHORIZE_ENDPOINT: '/org/oauth/v2/authorize',
  IGNORE_TOO_MANY_REQUESTS: 'too_many_requests',
  SANDBOX_ACCOUNT_TRANSFER_VALIDATE_API: 'org/api/v2/accounts/transfers/validate'
}

export default APP_CONSTANTS
